import { sanitize } from 'isomorphic-dompurify';

import s from './BodyHtml.module.css';

interface Props {
  html: string;
}

export default function BodyHtml(props: Props): JSX.Element {
  const { html } = props;

  return (
    <section className={s.container}>
      <h2 className={s.hTwo}></h2>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(html) || '',
        }}
      />
    </section>
  );
}
