import Head from 'next/head';

import { baseUrl } from '@/config';
import { getImgSrc } from '@/helpers/helpers';

interface Props {
  title: string;
  description: string;
  canonical?: string;
  ogImgUrl: string;
  ogImgExtension: string;
}

export default function MyHead(props: Props) {
  const {
    title,
    description,
    canonical,
    ogImgUrl = getImgSrc(''),
    ogImgExtension = 'png',
  } = props;

  return (
    <Head>
      <title>{title}</title>
      {canonical ? <link href={canonical} rel='canonical' /> : ''}
      <meta content={description} name='description' />
      <meta content={title} property='og:title' />
      <meta content='website' property='og:type' />
      <meta content={baseUrl} property='og:url' />
      <meta content={ogImgUrl} property='og:image' />
      <meta
        content={`image/${ogImgExtension}`}
        property='og:image:type'
      />
      <meta content='1200' property='og:image:width' />
      <meta content='630' property='og:image:height' />
      <meta content={title} property='og:image:alt' />
      <meta content={description} property='og:description' />
      <link
        href='https://ogp.me/ns/ogp.me.rdf'
        rel='alternate'
        type='application/rdf+xml'
      />
      <link
        href='https://ogp.me/ns/ogp.me.ttl'
        rel='alternate'
        type='text/turtle'
      ></link>
      <meta content='summary_large_image' name='twitter:card' />
      <meta content={`${ogImgUrl}`} name='twitter:image' />
    </Head>
  );
}
