import Cookies from 'js-cookie';

import { Locale } from '@/enums';

export const setLocaleToCookies = (locale: Locale): void => {
  if (!window || !document) {
    return;
  }

  Cookies.set('NEXT_LOCALE', locale);
};
