import { dollarsFetchUrl, fetchUrl } from '@/config';

export const getImgSrc = (id: string | undefined): string => {
  if (typeof id !== 'string') return '/img/default.jpg';

  return `${fetchUrl}/assets/${id}`;
};

export const getDollarsImgSrc = (id: string | undefined): string => {
  if (typeof id !== 'string') return '/img/default.jpg';

  return `${dollarsFetchUrl}/assets/${id}`;
};

export const getFormattedDate = (date: string | Date) => {
  return new Intl.DateTimeFormat('ru', {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  }).format(new Date(date));
};

export const getFormattedTime = (date: string | Date) => {
  return new Intl.DateTimeFormat('ru', {
    timeStyle: 'short',
  }).format(new Date(date));
};

export const getLongDateFormat = (date: string, locale: string) => {
  return new Date(date).toLocaleString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const splitArrayToChunks = <T>(
  arr: T[],
  chunkSize: number
): T[][] => {
  const chunks: T[][] = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk: T[] = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
};
