import { Currency, Operation } from '@/enums';
import { GetExchangesDataQuery } from '@/gql/graphql';
import { Exchange } from '@/types/ExchangesData';

import { CurrencySelector } from './CurrencySelector';
import { ExchangesList } from './ExchangesList/';
import { OperationSelector } from './OperationSelector';
import styles from './Rates.module.css';

const operationItems: Array<{ name: string; value: Operation }> = [
  { name: 'Buy', value: Operation.BUY },
  { name: 'Sell', value: Operation.SELL },
];

const currencyItems: Array<{ name: string; value: Currency }> = [
  { name: 'USD', value: Currency.USD },
  { name: 'EUR', value: Currency.EUR },
  { name: 'GBP', value: Currency.GBP },
  { name: 'TRY', value: Currency.TRY },
];

export type Props = {
  addressesLabel: string;
  currency: Currency;
  exchanges: Exchange[];
  onCurrencyChange: (currency: Currency) => void;
  onOperationChange: (operation: Operation) => void;
  operation: Operation;
  citySlug: string;
  data: GetExchangesDataQuery;
};

export const Rates = (props: Props) => {
  const {
    addressesLabel,
    currency,
    exchanges,
    onCurrencyChange,
    onOperationChange,
    operation,
    citySlug,
  } = props;

  const handleCurrencyChange = (currency: Currency): void => {
    onCurrencyChange(currency);
  };

  const handleOperationChange = (operation: Operation): void => {
    onOperationChange(operation);
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectors}>
        <CurrencySelector<Currency>
          citySlug={citySlug}
          className={styles.currency}
          currency={currency}
          items={currencyItems}
          onSelectedItemChange={handleCurrencyChange}
          operation={operation}
          selectedItem={currency}
        />
        <OperationSelector<Operation>
          citySlug={citySlug}
          className={styles.currency}
          currency={currency}
          items={operationItems}
          onSelectedItemChange={handleOperationChange}
          operation={operation}
          selectedItem={operation}
        />
      </div>
      <ExchangesList
        addressesLabel={addressesLabel}
        citySlug={citySlug}
        exchanges={exchanges}
        operation={operation}
      />
    </div>
  );
};
