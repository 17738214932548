import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { Operation } from '@/enums';
import { getDollarsImgSrc, getImgSrc } from '@/helpers/helpers';
import { showLastUpdate } from '@/helpers/showLastUpdate';
import { Exchange } from '@/types/ExchangesData';

import styles from './ExchangesList.module.css';

type PriceItem = {
  operation: Operation;
  value: number;
};

interface Props {
  addressesLabel: string;
  exchanges: Exchange[];
  operation: Operation;
  citySlug: string;
}

export const ExchangesList: FC<Props> = (props) => {
  const { addressesLabel, exchanges, operation, citySlug } = props;

  return (
    <ul className={styles.list}>
      {exchanges.map((exchange, index) => {
        const { name, rates, slug, logo } = exchange;
        const title = `${index + 1}. ${name}`;
        const priceItems: PriceItem[] = [
          { operation: Operation.BUY, value: rates.buy },
          { operation: Operation.SELL, value: rates.sell },
        ];
        const logoSrc = getDollarsImgSrc(logo?.id);

        return (
          <li className={styles.item} key={name}>
            <div
              className={styles.link}
              // href={`/${citySlug}/exchanges/${slug}`}
            >
              <div className={styles.exchangeRow}>
                <div className={styles.itemLeft}>
                  {logo?.id && (
                    <Image
                      alt={name}
                      height={48}
                      src={logoSrc}
                      width={48}
                    />
                  )}
                  <div className={styles.exchangeTitleBlock}>
                    {title}
                    <div
                      className={styles.time}
                      suppressHydrationWarning
                    >
                      <svg
                        height='12'
                        viewBox='0 -960 960 960'
                        width='12'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M480-120q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q82 0 155.5 35T760-706v-94h80v240H600v-80h110q-41-56-101-88t-129-32q-117 0-198.5 81.5T200-480q0 117 81.5 198.5T480-200q105 0 183.5-68T756-440h82q-15 137-117.5 228.5T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z' />
                      </svg>
                      {showLastUpdate(rates.dateCreated)}
                    </div>
                    {/* <div className={styles.officesCount}>
                      {offices &&
                        offices.length > 0 &&
                        `${offices} offices`}
                    </div> */}
                  </div>
                </div>
                <ul className={styles.itemRight}>
                  <div className={styles.prices}>
                    {priceItems.map((item) => (
                      <li
                        className={styles.priceGroup}
                        key={item.operation}
                      >
                        <div
                          className={`${styles.priceValue} ${
                            operation === item.operation
                              ? styles.rateActive
                              : ''
                          }`}
                        >
                          {item.value.toFixed(3)}
                        </div>
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
