import Link from 'next/link';
import { ChangeEvent } from 'react';

import { Locale } from '@/enums';
import { capitalizeString } from '@/helpers/capitalizeString';
import { CitySlug } from '@/types/CitySlug';

import styles from './Header.module.scss';

interface Props {
  citiesSlugs?: CitySlug[];
  citySlug?: CitySlug;
  locale: Locale;
  onCityChange?: (citySlug: CitySlug) => void;
  onLocaleChange?: (locale: Locale) => void;
}

const Header = (props: Props) => {
  const {
    citiesSlugs,
    citySlug = '',
    locale,
    onCityChange,
    onLocaleChange,
  } = props;

  const handleCitySlugChange = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    e.stopPropagation();

    if (!onCityChange) {
      return;
    }

    const selectedCity = e.target.value as Locale;
    onCityChange(selectedCity);
  };

  const handleLocaleChange = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    e.stopPropagation();

    if (!onLocaleChange) {
      return;
    }

    const selectedLocale = e.target.value as Locale;
    onLocaleChange(selectedLocale);
  };

  return (
    <header className={styles.header}>
      <div className={styles.centered}>
        <Link className={styles.logo} href={'/tbilisi/rates/usd/buy'}>
          Dollars.ge
        </Link>
        <div className={styles.rightGroup}>
          {!!citiesSlugs?.length && (
            <select
              className={styles.select}
              onChange={handleCitySlugChange}
              value={citySlug}
            >
              {citiesSlugs.map((city) => (
                <option key={city} value={city}>
                  {capitalizeString(city)}
                </option>
              ))}
            </select>
          )}
          <select
            className={styles.select}
            onChange={handleLocaleChange}
            value={locale}
          >
            <option value={Locale.EN}>EN</option>
            <option value={Locale.GE}>GE</option>
          </select>
        </div>
      </div>
    </header>
  );
};

export default Header;
