export const MS_SECONDS_IN_HOUR = 3_600_000;
export const HOURS_BEFORE_RATE_IS_OUTDATED = 2;
export const domain = 'dollars.ge';
export const baseUrl = 'https://dollars.ge';
export const graphQLurl = `https://api.dollars.ge/graphql`;
export const fetchUrl = 'https://api.mapi.ge/api/aggregator/fetch/';
export const dollarsFetchUrl = 'https://api.dollars.ge';
export const graphQLApiToken: string =
  process.env.API_TOKEN || 'invalid token';
export const defaultLocale = 'en';
