export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  TRY = 'try',
  GBP = 'gbp',
}

export const isCurrency = (v: unknown): v is Currency => {
  return (
    v === Currency.USD ||
    v === Currency.EUR ||
    v === Currency.GBP ||
    v === Currency.TRY
  );
};
