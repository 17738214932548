import Link from 'next/link';

import styles from './CurrencySelector.module.css';

type Props<T> = {
  className?: string;
  items: Array<{ name: string; value: T }>;
  onSelectedItemChange: (item: T) => void;
  selectedItem: T;
  citySlug: string;
  currency: string;
  operation: string;
};

export const CurrencySelector = <T,>(props: Props<T>) => {
  const { citySlug, className, items, selectedItem, operation } =
    props;

  return (
    <div className={`${styles.container} ${className}`}>
      {items.map((item) => {
        const { name, value: currencySlug } = item;
        const url = `/${citySlug}/rates/${currencySlug}/${operation}`;

        return (
          <Link
            className={`${styles.item} ${
              selectedItem === currencySlug ? styles.active : ''
            }`}
            data-value={currencySlug}
            href={url}
            key={name}
            type='button'
          >
            {name}
          </Link>
        );
      })}
    </div>
  );
};
