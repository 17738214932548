export function showLastUpdate(date: Date | string) {
  const userOffset = new Date().getTimezoneOffset();
  const unixTimestamp =
    new Date().getTime() -
    new Date(date).getTime() +
    1000 * 60 * userOffset;
  const dateDelta = new Date(unixTimestamp);
  const hours = dateDelta.getHours();
  const minutes = dateDelta.getMinutes();

  return (
    <>
      {hours > 0 ? `${hours} h` : ''}{' '}
      {minutes ? `${minutes} m` : '0 m'}
    </>
  );
}
